@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.objectives-mod {
  background-color: $color-white;
  color: $color-blueberry;
  text-align: left;
  margin: 0;
  padding-bottom: 40px;

  @include small {
    margin: 0;
    min-height: 100vh;
  }
}

.objectives-text {
  margin: 40px $mobile-margin 0;
  padding-top: 40px;
  color: $color-plum;
  max-width: 400px;
  
  @include small {
    margin: 40px $desktop-margin 0;
  }
}

.objectives-header {
  @include largeHeadline;
  width: 75%;
  margin-bottom: 0;

  @include small {
    width: 100%;
  }

  // &:focus {
  //   outline: none;
  // }
}

.objectives-cards {
  margin: 0;
  white-space: nowrap;
  display: flex;
  box-sizing: border-box;
  
  @include small {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 20px $desktop-margin - 30;
    overflow-x: visible;
    overflow-y: visible;
    white-space: inherit;
  }
}