@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.sticky-nav {
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  width: 83%;
  height: 100vh;
  transform: translateX(100%);
  visibility: hidden;
  transition-duration: .3s;
  transition-property: transform visibility;
  transition-timing-function: ease-in;
  background-color: $color-blueberry;
  color: $color-plum;
  text-align: left;
  z-index: 101;
  overflow-y: auto;
  
  &.show {
    transform: none;
    visibility: visible;
    transition-timing-function: ease-out;
  }

  button {
    border: none;
    background: none;

    &:focus {
      outline-color: rgb(0, 95, 204);
      outline-style: auto;
      outline-width: 1px;
    }
  }

  // desktop nav styles
  @include small {
    &:not(.forceMobileMenu) {
      box-sizing: border-box;
      position: fixed;
      top: 0;
      left: 0;
      right: auto;
      bottom:0;
      transform: translateX(-100%);

      padding: 122px 16px 0 16px;
      width: 168px;
      height: auto;
      z-index: 103;
      overflow-y: visible;
      display: block;

      .mobile-nav-header {
        display: none;
      }

      .nav-button {
        width: 100%;
        padding: 0;
        margin-top:30px;
        
        &:not(:last-of-type) {
          padding-bottom: 30px;
        }
        
        &:last-of-type {
          padding-bottom: 0;
        }
        
        &:not(:first-of-type) {
          margin-top: 0;
        }
      }
      .nav-button{
        display:block;
      }
      .nav-icon {
        display: block;
        padding-bottom: 10px;
      }
      
      .nav-text {
        font-weight: bold;
        padding: 0;
        line-height: 1;
      }
      // transition: none;

      // animation-duration: .15s;
      // animation-fill-mode:forwards;
      // animation-delay: .3s;    
      // animation-name: hideNav;
      &.show {
        transform: none;
      //   animation-name: showNav;
      //   animation-duration: .15s;
      //   animation-delay: 0s;
      //   animation-fill-mode:forwards;
      }
    }
  }

  .mobile-nav-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 61px;

    .close {
      padding-right: 20px;
      width: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include small {
        display:none;
      }       

    }
  }
}

.nav-button {
  display: flex;
  align-items: center;
  padding: 0 20px;
  @include navLabel;
  color: $color-champagne;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  filter:drop-shadow(0px 0 2px $color-blueberry);
  transition: color .3s linear,filter .3s linear ;
  svg path{
    fill: $color-champagne;
    transition: fill .3s linear,filter .3s linear ;
  }

  &:hover,&.active {
    color: $color-grapefruit!important;
    svg path {
      fill: $color-grapefruit!important;
    }
  }

  &:last-of-type {
    padding-bottom: 45px;
  }

  &:not(:first-of-type) {
    margin-top: 45px;
  }
}

.nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 32px;
  }
}

.nav-text {
  padding: 0 20px;
  font-family: $font-family-default;
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
}

@keyframes showNav {
  from {left:-100%;}
  to {left:0;}
}
@keyframes hideNav {
  from {left:0;}
  to {left:-100%;}
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $color-blueberry;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  transition: all .3s;

  &[data-visible="true"] {
    visibility: visible;
    opacity: .4;
  }

  @include small {
    z-index: 102;

  } 
}