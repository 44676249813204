@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.welcome-mod {
  width: 100%;
  background-color: $color-white;
  color: $color-blueberry;
  text-align: left;
  height: auto;
}

.welcome-text {
  @include body;
  margin: 0 $mobile-margin 0;
  box-sizing: border-box;
  padding-top:20px;
  padding-bottom: 60px;
  color: $color-plum;
  max-width: 1160px;


  @include small {
    margin: 0 0 0 $desktop-margin;
    padding-top:46px;
  }
}

.welcome-header {
  @include jumboHeadline;
  margin-top:0;
  margin-bottom: 20px;
  // outline: none;

  // &:focus {
  //   outline: none;
  // }
}

.welcome-body {
  @include body;
  color: $color-black;
  line-height: 1.5;
  max-width: 80vw;

  @include small {
    max-width: 60vw;
  }
}

.welcome-video {
  video {
    background: #000;
    width: 100%;
    max-height: 90vh;
  }
  @include body;
  color: $color-black;
}