@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.App {
  text-align: center;
  overflow-x: hidden;
}

body {
  background-color: $color-blueberry;
  overflow-x: hidden;
}

.grapefruit {
  color: $color-grapefruit;
}
.Modal-Overlay {
  position: fixed;
  z-index:101;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width:100%;
  max-width: 85vw; 
  padding: 10px;
  background: $color-champagne;
  border-radius: 4px;
  @include medium {
    max-width: 50vw; 
    padding: 20px;
  }  
  h2 {
    @include largeHeadline;    
  }
  p {
    @include body;    
  }
  .buttons {
    display: inline-block;
  }
  .button{
    background: $color-medium-blue;
    border: none;
    padding: 1rem 2rem;
    border-radius: 4px;
    color:#FFF;
    text-decoration: none;
    cursor: pointer;
    margin-right: 1rem;
  }
  button {
    @include body;    
    text-decoration: underline;
    background: none;
    border: none;
    color:$color-medium-blue;
  }
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none !important;
}