@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: $color-blueberry;
  color: $color-white;
  font-weight: bold;
  text-align: left;
  justify-content: center;
  padding: 16px;
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 54px;

  @include small {
    width: 52.3%;
    padding-top: 68px;
  }
}

.footer-icon {
  
  img {
    width: 30px;
    height: 30px;
  
    @include small {
      width: 40px;
      height: 40px;
      margin: 0;
    }
    
  }
}

.footer-rule {
  margin-top: 60px;
  margin-bottom: 16px;
  width: 100%;
  
  @include small {
    width: 90%;
    margin-top: 70px;
    margin-bottom: 30px;
  }
}

.footer-bottom {
  width: 100%;

  @include small {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 90%;
  }
  
  &-links {
    display: flex;
    margin-bottom: 24px;
    width: 100%;
    
    @include small {
      display: flex;
      justify-content: space-around;
      width: auto;
    }

    .footer-link {
      &:not(:last-of-type) {
        margin-right: 20px;
      }

      a {
        @include footerLink;
        color: $color-light-blue;
        text-decoration: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
    
  }
}

.footer-legal {
  font-family: $font-family-default;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: $color-champagne;
  
  @include small {
    width: 85%;
    margin-right: auto;
  }
}