@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.for-teachers-mod {
  position: relative;
  color: $color-blueberry;
  text-align: left;
  min-height: 800px;
  margin: 0;
  

  
  @include medium {
    min-height: 60vh;
  }
  
  @include large {
    min-height: 76vh;
  }
}

.for-teachers-upper {
  position: relative;
  background: no-repeat url('./images/for_teachers_background_mobile.jpg'), $color-light-blue;
  background-size: cover;
  background-position: center bottom;
  height: 100%;
  min-height: 850px;
  padding: 64px 20px 100px 20px;
  
  @include small {
    background: no-repeat url('./images/for_teachers_background.jpg'), $color-light-blue;
    background-size: cover;
    width: 100%;
    padding: 64px 20px 0;
  }

  img {
    display: none;

    @include small {
      display: block;
      vertical-align: top;
      width: 100%;
      opacity: 0;
    }
  }

  .for-teachers-upper__content {
    @include small {
      display: flex;
      flex-wrap: wrap;
      padding: 127px 0 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.for-teachers-text {
  color: $color-plum;
  margin: 0;
  
  @include small {
    margin: 0 0 0 $desktop-margin ;
    flex: 1 1 30%;
  }
}

.for-teachers-header {
  @include largeHeadline;
  margin: 0 0 20px;

  @include small {
    font-size: 36px;
    line-height: 40px;
  }

  @include medium {
    @include largeHeadline;
  }

  // &:focus {
  //   outline: none;
  // }
}

.for-teachers-body {
  @include body;
  color: $color-black;
  
  @include medium {
    max-width: 36vw;
    font-size: 24px !important;
    line-height: 32px !important;
  }
  
  &__small {
    @include legal;
    color: $color-black;
    
    a {
      color: $color-medium-blue;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.for-teachers-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  background-color: $color-white;
  color: $color-black;
  padding: 30px 0 24px 30px;
  margin:  40px 0;
  max-width: 620px;
  
  @include small {
    flex: 1 1 20%;
    margin:  0 5%;
  }
}

.for-teachers-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;

  @include small {
    align-items: center;
  }

  &__icon {
    @include subHeadline;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    background-color: $color-grapefruit;
    width: 46px;
    height: 46px;
    margin: 5px 0;
  }

  &__text {
    @include body;
    margin: 0 20px;
    width: 80%;

    a {
      color: $color-link-blue;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.for-teachers-download {
  @include body;
  
  @include small {
    margin: 14px 0 0 66px;
  }

  a {
    color: $color-link-blue;
    text-decoration: none;
    font-weight: bold;
  }


}

.for-teachers-lower {
  @include body;
  background-color: $color-teachers-gray;
  color: $color-light-black;
  padding: 16px;

  a {
    color: $color-link-blue;
    text-decoration: none;
    font-weight: bold;
  }

  @include small {
    background: no-repeat url('./images/for_teachers_background_lower.jpg'), $color-light-blue;
    background-size: cover;
    padding: 30px 0 100px $desktop-margin;
  }

  &__text {
    @include small {
      max-width: 50vw;
    }
  }
}
