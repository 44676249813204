@import 'breakpoints';

/* Color Palette */
/* ---------------------------------------- */

/* Primary */
$color-plum: #650360;
$color-champagne: #fff7f0;
$color-blueberry: #371447;
/* ------- */

/* Secondary */
$color-grapefruit: #cb3974;
$color-seafoam: #1c988a;
/* ------- */

/* Utility */
$color-medium-blue: #0071c4;
$color-medium-gray: #707070;
$color-teachers-gray: #CFCDC5;
$color-light-gray: #eeeeee;
$color-light-black: #2a2a2a;
$color-link-blue:#006899;
$color-light-blue: #8bd3f5;
$color-white: #ffffff;
$color-black: #000000;
/* ------- */

/* ---------------------------------------- */


/* Header */
/* ---------------------------------------- */
$top-nav-height: 61px;
/* ---------------------------------------- */


/* Fonts */
/* ---------------------------------------- */
$font-family-boing-bold: 'Boing_Bold', Helvetica, Arial, sans-serif;
$font-family-default: Arial, Helvetica, sans-serif;
/* ---------------------------------------- */


/* Typography */
/* ---------------------------------------- */
@mixin jumboHeadline {
  font-family: $font-family-boing-bold;
  font-size: 52px;
  line-height: 50px;
  letter-spacing: .8px;
  
  @include medium {
    font-size: 68px;
    line-height: 72px;
  }
  
  @include large {
    font-size: 98px;
    line-height: 90px;
  }
}

@mixin largeHeadline {
  font-family: $font-family-boing-bold;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: .8px;

  @include large {
    font-size: 68px;
    line-height: 74px
  }
  &:focus {
    outline: $color-champagne auto 1px;
    outline-offset: 3px;
  }
}

@mixin subHeadline {
  font-family: $font-family-boing-bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: .8px;

  @include small {
    font-size: 24px;
    line-height: 26px;
  }

  @include large {
    font-size: 32px;
    line-height: 34px;
  }
}

@mixin quoteText {
  font-family: $font-family-boing-bold;
  font-size: 38px;
  line-height: 40px;
  letter-spacing: .8px;
  
  @include small {
    font-size: 68px;
    line-height: 72px;
  }
  
  @include large {
    font-size: 84px;
    line-height: 86px;
  }
}

@mixin body {
  font-family: $font-family-default;
  font-size: 16px;
  line-height: 24px;

  @include small {
    font-size: 20px;
    line-height: 1.333;
  }

  @include large {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin bodySmall {
  font-family: $font-family-default;
  font-size: 12px;
  line-height: 1.5;
}

@mixin navLabel {
  font-family: $font-family-boing-bold;
  font-size: 12px;
}

@mixin animationText {
  font-family: $font-family-default;

  @include small {
    font-size: 24px;
    font-weight: normal;
  }
}

@mixin toggleText {
  font-family: $font-family-boing-bold;
  font-size: 34px;
  letter-spacing: .8px;
}

@mixin legal {
  font-family: $font-family-default;
  font-size: 12px;
  
  @include small {
    font-size: 10px;
  }
}

@mixin footerLink {
  font-family: $font-family-default;
  font-size: 16px;
  line-height: 24px;
}
/* ---------------------------------------- */