@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.objectives-card {
  display: inline-block;
  text-align: left;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  @include small {
    width: 33%;
  }

  &__icon {
    
    img {
      width: 100%;

      @include small {
        max-width: 100%;
      }

      @include large {
        max-width: 435px;
      }
    }
  }

  &__text {
    overflow-x: auto;
    overflow-y: auto;
    white-space: initial;
  }

  &__header {
    color: $color-grapefruit;
    @include subHeadline;
    margin: 24px 0 0;

    &:focus {
      outline: none;
    }
  }

  &__body {
    @include body;
    margin: 14px 0;
    max-width: 90%;
  }
}