@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.overview-mod {
  background-color: $color-blueberry;
  color: $color-white;
  text-align: left;
  @include small {
    min-height:100vh;
  }
  #overview-pin{
    padding: 60px 0 0;
    margin: 0;
    position: relative;
    
    @include small {
      display: flex;
      flex-wrap: wrap;
      padding: 0 !important;
    }  
  }
}

.overview-text {
  margin: 20px $mobile-margin;
  color: $color-white;
  flex-wrap: wrap;

  @include small {
    margin: 20px 0 80px $desktop-margin;
    width: 70vw;
  }
}

.overview-header {
  @include largeHeadline;
  color: $color-white;
  margin: 0;
  margin: 0 0 16px $mobile-margin;
  
  @include small {
    margin: 70px 0 0 $desktop-margin;
  }

  h3 {
    margin: 0;
  }

  // &:focus {
  //   outline: none;
  // }
}

.overview-quote {
  @include subHeadline;

  @include small {
    max-width: 60%;
  }
}

.overview-citation {
  @include body;
  a {
    color:$color-light-blue;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

}

.bank {
  display: block !important;
  position: absolute;
  top: 640px;
  z-index: 0;
  
  @include small {
    position: relative;
    top: 3vw;
    margin: 5vw 0 0 -25vw;
    width: 20vw;
  }
}

.bank-image {
  width: 100vw;
  display: none;
  
  @include small {
    display: block;
    width: 55vw;
    overflow: visible;
    margin-top: 12vw;
  }

  @include medium {
    margin-top: 0;
  }

}

.overview-cards {
  margin: 0;
  z-index: 5;

  @include small {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 20px $desktop-margin;

    &:not(.forceMobileMenu) {
      height: 1vh;
    }

    .mobile-background {
      display: none;
    }
  }

  .mobile-background {
    margin-top: 116px;
    background-image: url('./images/bank_mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 345px;

    @include smallPhoneMax {
      background-size: contain;
      background-position: center center;
    }
  }
}

.static {
  display: flex;
  transform: none !important;
}

.overview-card {
  display: flex !important;
  margin: 40px 10px;

  @include small {
    margin: 20px 10px 20px 0;
    width: 25vw;
  }

  &__icon {
    img {
      width: 75px;
      margin-left: 15px;
      
      @include small {
        width: 7vw;
        margin-left: 0;
      }
    }
  }
  
  &__text {
    margin: 0 16px 0;
  }

  &__header {
    @include subHeadline;
    margin: 0 0 14px 0;
    color: $color-grapefruit;
    
    @include small {
      margin: 0 0 4px 0;
      font-size: 20px;
      line-height: 20px;
      width: 10vw;
    }
    
    @include medium {
      @include subHeadline;
      margin: 0 0 14px 0;
    }
  }

  &__body {
    @include body;

    @include small {
      font-size: 18px;
      line-height: 20px;
    }

    @include medium {
      font-size: 20px;
      line-height: 22px;
    }

    @include large {
      @include body;
    }
  }
}