@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.how-to-play-mod {
  text-align: left;
  background: $color-champagne;
}

.how-to-play-image {
  background: $color-champagne;
  
  img {
    width: 140%;
    margin-left: -40vw;

    @include medium {
      vertical-align: top;
      width: 115%;
      opacity: 0;
    }
  }
  
  @include medium {
    position: relative;
    background: no-repeat url('./images/how_to_play_background.png');
    background-size: cover;

    .how-to-play__upper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      &.forceMobileMenu {
        .how-to-play-text {
          padding-top: 3vw;
        }
      }
    }
  }
}

.how-to-play-text {
  padding: 40px $mobile-margin 80px;

  @include small {
    padding: 40px 5% 80px $desktop-margin;

  }

  @include medium {
    margin: 0;
    padding-top: 6.25vw;
    padding-right: 0;
    padding-bottom: 0;
    max-width: 33vw;
  }

  @media (min-width: 1920px) {
    max-width: 29vw;
  }

  @media (min-width: 2200px) {
    max-width: 25vw;
  }
}

.how-to-play-header {
  @include largeHeadline;
  color: $color-plum;
  margin: 0;
  margin-bottom: 16px;

  @include medium {
    font-size: 32px;
    line-height: 36px;
  }

  @media (min-width: 1300px) {
    @include largeHeadline;
  }

  .nowrap {
    @include small {
      white-space: nowrap;
    }
  }

  // &:focus {
  //   outline: none;
  // }
}

.how-to-play-body {
  @include body;
  color: $color-black;

  @media (min-width: 1024px) {
    font-size: 20px;
//    max-width: 17vw;
  }

  @include large {
    @include body;
    max-width: none;
  }
}

.how-to-play__lower {
  position: relative;
  background-color: $color-blueberry;
  color: $color-white;
  padding: 30px 0 40px;
  
  @include small {
    margin: 0;
    padding: 0;
  }
}

.how-to-play-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -112px 0 20px;
  
  @include small {
    justify-content: space-between;
    margin: -1px 2% 0 $desktop-margin;
  }
  
  @include medium {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: -1px 2% 0 $desktop-margin;
  }

}

.how-to-play-card {
  position:relative;
  width: 100%;
  
  @include small {
    // margin: 0 1vw;
    display: flex;
    justify-content: center;
    padding: 18vh 18px 4vh;
    min-height: 100%;

    
    @include medium {
      padding: 60px 18px 6vh;
      width: 20vw;

      &:not(:last-of-type) {
        border-right: 1px solid white;
      }
    }

  }
  
  &__icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 30px auto;
    background-color: $color-white;
    
    @include small {
      position: absolute;
      top: -4vh;
      width: 75px;
      height: 75px;
      margin: 10% 20% 30px 20%;
      left: 12%;
    }
    
    @include medium {
      width: 100px;
      height: 100px;
      margin: 0 20% 30px 20%;
    }
    
    @include large {
      top: -3.5vw;
      width: 138px;
      height: 138px;
      margin: 0 20% 30px 20%;
    }

    img {
      width: 65%;
      height: 50%;
    }
  }

  &__text {
    @include body;
    margin: 2vw 10px 0 10px;
    padding: 0 10px;
    
    a {
      color: $color-light-blue;
      text-decoration: none;
      font-weight: bold;
    }

    @include small {
      width: 100%;
    }

    @include medium {
      margin: 30px 10px 0 10px;
      width: auto;
    }

    @include large {
      margin: 2vw 10px 0 10px;
    }
  }

}

.mobile-border {
  border-top: 1px solid $color-white;
  position: absolute;
  width: 100%;
  top: 38%;
  left: 0;

  @include medium {
    display: none;
  }
}