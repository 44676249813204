@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

#HeroVideo{
	position: relative;
	overflow: hidden;
}

.welcome-video {
	display: flex;
	width: 100vw;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: 100%;
	margin-top: 61px; /* for mobile sticky header */
  
	@include small {
		margin-top: 0;
		z-index:101;
	}
  
	.play-button {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  width: 123px;
	  cursor: pointer;
	  display: none;
    background: none;
    border: none;
    padding: 0;
  
	  &:focus {
		outline: 1px solid white;
	  }
  
	  @include small {
		width: 300px;
	  }
  
	  &.show {
		display: block;
	  }
  
	  img {
		width: 100%;
	  }
	}
  
	.video-thumbnail {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 100%;
	  width: 100%;
	  display: none;
  
	  &.show {
		display: block;
	  }
  
	  img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	  }
	}
  }
  