/* Breakpoint Values */
/* ---------------------------------------- */
$breakpoint-small-phone-end: 375px;
$breakpoint-small-end: 859px;
$breakpoint-medium: 860px;
$breakpoint-medium-end: 1023px;
$breakpoint-large: 1600px;
$content-x-large: 1800px;
/* ---------------------------------------- */

/* Default Margins */
/* ---------------------------------------- */
$mobile-width: 400px;
$mobile-margin: 16px;
$desktop-margin: 200px;
/* ---------------------------------------- */


/* Media Queries */
/* ---------------------------------------- */
@mixin smallPhoneMax {
  @media (min-width: $breakpoint-small-phone-end) {
    @content;
  }
}

@mixin small {
  @media (min-width: $breakpoint-small-end) {
    @content;
  }
}

@mixin medium {
  @media (min-width: $breakpoint-medium-end) {
    @content;
  }
}

@mixin large {
  @media (min-width: $breakpoint-large) {
    @content;
  }
}

@mixin xLarge {
  @media (min-width: $content-x-large) {
    @content;
  }
}
/* ---------------------------------------- */