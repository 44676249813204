@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';


.about-mod {
  position: relative;
  min-height: 620px;
  height: 620px;
  background-color: #D3EBFF;

  @include medium {
    min-height: 45vw;
    height: 45vw;
    background: url('./images/about_background.jpg');
    background-size: cover;
    background-position: 100% center;
  }

  .about-container {
    position: relative;
    padding: 40px $mobile-margin 0;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    
    @include small {
      padding-left: $desktop-margin;
      padding-right: 40px;
    }

    @include medium {
      padding-top: 6.67vw;
      padding-right: 0;
      padding-bottom: 6.67vw;
      height: 100%;
    }

    .about-box {
      position: relative;
      background-color: $color-blueberry;
      text-align: left;
      padding: 40px $mobile-margin;
      z-index: 2;

      @include medium {
        width: calc(36.77vw - (3.125vw * 2));
        padding: 3.125vw;
      }

      @media (min-width: $breakpoint-medium-end) and (max-width: 1200px) {
        width: 50%;
      }

      @media (min-width: $breakpoint-large) and (max-width: 1770px) {
        width: 40%;
      }
      
      .about-header {
        @include largeHeadline;
        color: $color-white;
        margin: 0;
        margin-bottom: 16px;

        @include medium {
          margin-bottom: 20px;
        }

        // &:focus {
        //   outline: none;
        // }
      }
  
      .about-body {
        @include body;
        color: $color-white;
        margin: 0;
      }
    }

    .about-image-mobile {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scale(1.5);
      transform-origin: bottom center;
      z-index: 1;

      @include medium {
        display: none;
      }

      img {
        width: 100%;

        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(-10%);
      }
    }
  }
}

