@import '../css/_breakpoints.scss';
@import '../css/_variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-blueberry;
  color: white;
  font-weight: bold;
  text-align: left;
  height: 61px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  @include small {
    justify-content: center;

    position: inherit;
    height: 102px;
  }
  .hamburger {
    position: fixed;
    top: 0;
    right: 0;
    height: 61px;
    z-index: 200;
    cursor: pointer;
    background: none;
    border: none;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

  
    @include small {
      &:not(.forceMobileMenu) {
        left: 0;
        right: auto;
        z-index: 200;
        width: 168px; 
        height: 102px; 
        background: $color-blueberry;
        display: flex;
        align-items: center;
        justify-content: center;
      
      }
    }
  }  
}

.logo {
  display: flex;
  padding-left: 16px;

  @include small {
    padding-left: 0px;
  }
}

.fintropolis-logo {
  width: 100px;
  padding-right: 14px;
  border-right: 1px solid $color-white;
  
  @include small {
    width: 240px;
    padding-right: 38px;
  }
}

.ally-logo {
  width: 45px;
  padding-left: 15px;
  align-self: center;
  
  @include small {
    width: 78px;
    padding-left: 38px;
  }
}

.animation-switch {
  display: none;
  
  @include small {
    display: flex;
    display: inherit;
    margin-right: 6vw;
  }

  &.forceMobileMenu {
    display: none;
  }
}

.animation-text {
  
  @include small {
    @include animationText;
    align-self: center;
    margin-right: 20px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 112px;
  height: 54px;
  border-radius: 34px;
  cursor: pointer;

  &:focus {
    outline-color: rgb(0, 95, 204);
    outline-style: auto;
    outline-width: 1px;
  }

  &.on {
    background-color: $color-grapefruit;

    .toggle-text {
      left: 57px;
      color: $color-white;
    }
  }

  &.off {
    background-color: $color-medium-gray;

    .slider {
      -webkit-transform: translateX(58px) translateY(-50%);
      -ms-transform: translateX(58px) translateY(-50%);
      transform: translateX(58px) translateY(-50%);
    }

    .toggle-text {
      left: 12px;
      color: $color-white;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    display: inline-block;
    position: absolute;
    height: 42px;
    width: 42px;
    left: 7px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    transition: .4s;
    background-color: $color-white;
    border-radius: 50%;
  }

  .toggle-text {
    @include toggleText;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

#toggle-box {
  height: 60px;
  width: 120px;
  background: none;
  border: none;
}

